import * as ActionTypes from './actionTypes'

export const initialSurveyState = {
  submissionId: null,
  id: null,
  name: null,
  showEventGroupWelcomePage: false,
  // Initial welcome page to load survey configurations
  configurationCode: '',
  attendeeEmail: '',
  attendeeName: '',
  attendeeOptIn: true,
  configurationLoaded: false,
  codeInUrl: false,
  codeValid: false,
  configurationOptions: {
    disableLeadgen: false,
  },
  // For event planners
  eventGroup: {},
  event: {},
  eventList: [],
  // All of the potential speakers for an event
  speakers: [],
  // The speaker is the "account owner"
  // Will need something else for event planners?
  speaker: {
    name: null,
    avatar: null,
    email: null,
    shortlink: null,
    id: null,
    // Flag for if the speaker is an event planner
    isEventPlanner: false,
    isCeProvider: false,
    showSurveyOfferQuestions: true,
    disableLeadgenQuestions: false,
    bookingLink: null,
    canShowBookingLink: null,
    hideFreebieLink: null,
    socialLinks: {
      instagram: null,
      twitter: null,
      linkedin: null,
      youtube: null,
    },
  },
  questions: [],
  possibleAnswers: [],
  customQuestion: {
    question: null,
    possibleAnswers: [],
  },
  answers: [],
  totalQuestionsAnswered: 0,
  percentComplete: 0,
  isLoading: false,
  customQuestionActive: false,
  customQuestionLoaded: false,
  previousQuestionIds: [],
  submittedQuestionIds: [],
  firstQuestionIdentifier: null,
}

export const surveyReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_EVENT_PLANNER_WELCOME_QUESTION:
      return {
        ...state,
        ...action.payload,
      }
    case ActionTypes.LOAD_SURVEY:
      return {
        ...state,
        ...action.payload,
        questions: action.payload.questions.map((question, i) =>
          question.identifier === 0
            ? { ...question, isActive: true }
            : { ...question, isActive: false }
        ),
      }
    case ActionTypes.SET_CURRENT_QUESTION:
      return {
        ...state,
        totalQuestionsAnswered:
          action.payload.question?.enabled === false
            ? state.totalQuestionsAnswered
            : state.totalQuestionsAnswered + action.payload.direction,
        questions: state.questions.map((question, i) =>
          question.id === action.payload.question.id
            ? { ...question, isActive: true }
            : { ...question, isActive: false }
        ),
      }
    case ActionTypes.SET_PREVIOUS_QUESTION_ID:
      return {
        ...state,
        previousQuestionIds: state.previousQuestionIds.concat(action.payload),
      }
    case ActionTypes.SET_SUBMITTED_QUESTION_IDS:
      if (state.submittedQuestionIds.indexOf(action.payload) === -1) {
        return {
          ...state,
          submittedQuestionIds: state.submittedQuestionIds.concat(
            action.payload
          ),
        }
      }
      return {
        ...state,
      }
    case ActionTypes.SET_CUSTOM_QUESTION:
      return {
        ...state,
        customQuestion: {
          question: action.payload.question,
          possibleAnswers: action.payload.possibleAnswers,
        },
      }
    case ActionTypes.REMOVE_PREVIOUS_QUESTION_ID:
      return {
        ...state,
        previousQuestionIds: state.previousQuestionIds.filter(
          (id, i) => id !== action.payload
        ),
      }
    case ActionTypes.SET_CUSTOM_QUESTION_ACTIVE:
      if (!state.customQuestionLoaded) {
        return {
          ...state,
          questions: state.questions
            .map((question, i) => question && { ...question, isActive: false })
            .concat({ ...action.payload.question, isActive: true }),
          possibleAnswers: state.possibleAnswers.concat(
            action.payload.possibleAnswers
          ),
          customQuestionActive: true,
          customQuestionLoaded: true,
        }
      }
      return {
        ...state,
        questions: state.questions.map((question, i) =>
          question.id === action.payload.question.id
            ? { ...question, isActive: true }
            : { ...question, isActive: false }
        ),
        customQuestionActive: true,
      }
    case ActionTypes.SET_CUSTOM_QUESTION_INACTIVE:
      return {
        ...state,
        customQuestionActive: false,
      }
    case ActionTypes.ADD_ANSWER:
      return {
        ...state,
        possibleAnswers: state.possibleAnswers.map((answer, i) =>
          answer.id === action.payload.id
            ? { ...answer, answerValue: action.payload.answerValue }
            : { ...answer }
        ),
      }
    case ActionTypes.SET_ANSWERS:
      const { answers } = action.payload
      return {
        ...state,
        answers: answers,
      }
    case ActionTypes.SET_PERCENT_COMPLETE:
      return {
        ...state,
        percentComplete: action.payload,
      }
    case ActionTypes.SET_ATTENDEE_EMAIL:
      return {
        ...state,
        attendeeEmail: action.payload,
      }
    case ActionTypes.SET_ATTENDEE_NAME:
      return {
        ...state,
        attendeeName: action.payload,
      }
    case ActionTypes.CLEAR_SURVEY:
      return {
        ...initialSurveyState,
      }
    case ActionTypes.SET_CONFIGURATION_CODE:
      return {
        ...state,
        configurationCode: action.payload,
      }
    case ActionTypes.SET_FIRST_QUESTION_IDENTIFIER:
      return {
        ...state,
        firstQuestionIdentifier: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
