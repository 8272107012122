import Api from '../../services/api'

export const findCurrentQuestion = (survey) => {
  return survey.questions.filter((question, i) => !!question.isActive)[0]
}

export const findPossibleAnswersByQuestion = (survey, question) => {
  return survey.possibleAnswers.filter(
    (answer, i) => answer.questionId === question.id
  )
}

export const findQuestionById = (survey, questionId) => {
  return survey.questions.find((question, i) => question.id === questionId)
}

export const findQuestionByIdentifier = (survey, identifier) => {
  return survey.questions.find(
    (question, i) => question.identifier === identifier
  )
}

export const findQuestionByPossibleAnswer = (survey, possibleAnswer) => {
  return survey.questions.find(
    (question, i) => question.id === possibleAnswer.questionId
  )
}

// find all answers by type
const findAnswersByAnswerType = (survey, answerType) => {
  return survey.possibleAnswers.filter(
    (answer, _i) => answer.answerType === answerType
  )
}

const answerValueExists = (answers) => {
  return answers.some((answer, _i) => !!answer.answerValue)
}

export const checkSubContent = (subContent, hasCustomQuestion) => {
  if (subContent) {
    if (hasCustomQuestion) {
      switch (subContent) {
        case 'Just 2 last questions!':
          // Don't show anything
          return
        case 'Very Last Question!':
          subContent = 'Just 2 last questions!'
          break
        default:
          break
      }
    }
    return subContent
  }
}

export const findExistingAnswer = (question, possibleAnswer, answers) => {
  // If there are multiple answers associated with the question we have to find the answer by the possible_answer_id,
  // otherwise we can just find the answer by the question_id so that we can properly reassign the possible_answer_id
  if (
    question.questionType === 'MULTI_INPUT' ||
    question.questionType === 'FREE_FLOW'
  ) {
    return answers.find(
      (answer, _i) => answer.possible_answer_id === possibleAnswer.id
    )
  } else {
    return answers.find((answer, _i) => answer.question_id === question.id)
  }
}

export const isLeadQualified = (survey) => {
  // TODO: check if we still want to use this logic
  return (
    (answerValueExists(findAnswersByAnswerType(survey, 'EMAIL')) ||
      answerValueExists(findAnswersByAnswerType(survey, 'PHONE'))) &&
    answerValueExists(findAnswersByAnswerType(survey, 'ORGANIZATION')) &&
    answerValueExists(findAnswersByAnswerType(survey, 'JOB_TITLE')) &&
    answerValueExists(findAnswersByAnswerType(survey, 'NAME'))
  )
}

export const findEmail = (survey) => {
  return survey.attendeeEmail
}

export const findName = (survey) => {
  return survey.attendeeName
}

export const findOfferCode = (survey) => {
  // Handle event planner loading events with specific codes case here
  // Each code can now have it's own offer and bonus
  if (survey.event?.event_code) {
    return survey.event.event_code
  }

  return survey.configurationCode?.trim()
}

export const makeAnswerParams = (survey, possibleAnswer, answerValue) => {
  const question = findQuestionByPossibleAnswer(survey, possibleAnswer)
  const existingAnswer = findExistingAnswer(
    question,
    possibleAnswer,
    survey.answers
  )

  return {
    possible_answer_id: possibleAnswer.id,
    question_id: question.id,
    question_type: question.questionType,
    question_content: question.content,
    answer_value: answerValue,
    answer_type: possibleAnswer.answerType,
    answer_id: existingAnswer?.id,
    submission_id: survey.submissionId,
    lead_type_id: possibleAnswer.leadTypeId,
    emoji_keyword: question.emojiKeyword,
  }
}

export const calcPercentComplete = ({
  survey,
  question,
  direction,
  customQuestion,
  surveyComplete,
}) => {
  let totalRemainingQuestions = 0

  if (surveyComplete) {
    totalRemainingQuestions = 0
  } else if (customQuestion) {
    // NOTE: This assumes that the custom question is the last question
    totalRemainingQuestions = 1
  } else {
    totalRemainingQuestions = findLongestSequence(survey, question.id)
    // NOTE: The custom question will never be part of the longest sequence
    // so we need to add it here
    if (survey.customQuestion.question) {
      totalRemainingQuestions += 1
    }
  }

  const totalAnsweredQuestions = survey.totalQuestionsAnswered + direction
  const totalForSurvey = totalAnsweredQuestions + totalRemainingQuestions
  const percentComplete = (totalAnsweredQuestions / totalForSurvey) * 100
  return parseInt(percentComplete)
}

export const submitAnswer = async (answerParams) => {
  // Don't submit any answers if the survey is in test mode
  if (isTestMode()) {
    return
  }

  try {
    return await Api.createAnswer({ answers: answerParams })
  } catch (err) {
    alert(JSON.stringify(err))
  }
}

export const isTestMode = () => {
  return window.location.search.includes('test')
}

export const QUESTION_TYPES = {
  BOOLEAN: 'BOOLEAN',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  FREE_FLOW: 'FREE_FLOW',
  EMAIL: 'EMAIL',
  EMOJI_SCALE: 'EMOJI_SCALE',
}

export const ANSWER_TYPES = {
  BOOLEAN: 'BOOLEAN',
  SLIDER: 'SLIDER',
  FREE_FLOW: 'FREE_FLOW',
  EMAIL: 'EMAIL',
  SHORT_TEXT: 'SHORT_TEXT',
  CODE: 'CODE',
  CHECKBOX: 'CHECKBOX',
  PHONE: 'PHONE',
  EMOJI_SCALE: 'EMOJI_SCALE',
  NAME: 'NAME',
  JOB_TITLE: 'JOB_TITLE',
  ORGANIZATION: 'ORGANIZATION',
  OPT_OUT_LINK: 'OPT_OUT_LINK',
  MULTI_INPUT: 'MULTI_INPUT',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  OPPORTUNITY_DETAILS: 'OPPORTUNITY_DETAILS',
}

export const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== 'string') return ''

  return string.charAt(0).toUpperCase() + string.slice(1)
}

const findLongestSequence = (survey, questionId) => {
  const allSequences = []
  const possibleAnswers = survey.possibleAnswers

  const findSequence = (questionId, sequence = []) => {
    const question = findQuestionById(survey, questionId)

    if (question?.enabled === false) {
      // no more questions for this sequence
      if (!question.skipQuestionIdentifier) {
        allSequences.push(sequence)
        return
      }

      // go to next question
      const nextQuestionId = findQuestionByIdentifier(
        survey,
        question.skipQuestionIdentifier
      )
      findSequence(nextQuestionId, sequence)
    }

    // get the possible answers for the current question based on the questionId
    const possibleAnswersForQuestion = possibleAnswers.filter(
      (answer, i) => answer.questionId === questionId
    )

    // if there are no possible answers for the current question, then we have reached the end of the sequence
    if (possibleAnswersForQuestion.length === 0) {
      allSequences.push(sequence)
      return
    }

    // loop through the possible answers for the current question
    possibleAnswersForQuestion.forEach((answer, i) => {
      // add the current answer to the sequence
      const newSequence = [...sequence, answer]

      // if the current answer has a nextQuestionId, then we need to find the next sequence
      if (answer.nextQuestionId) {
        findSequence(answer.nextQuestionId, newSequence)
      } else {
        // otherwise we have reached the end of the sequence
        allSequences.push(newSequence)
      }
    })
  }

  findSequence(questionId)

  // find the longest sequence
  const longestSequence = allSequences.reduce((a, b) =>
    a.length > b.length ? a : b
  )

  return longestSequence.length
}
